(function ($) {
  'use strict';

  var $html = $('html');


  /**
   * ユーザーエージェント
   * IE、Edgeを判定
   */
  userAgent();
  function userAgent() {
    var userAgent = window.navigator.userAgent.toLowerCase();
    // IEの場合
    if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
      $html.addClass('ie legacyBrowser');
    // 旧Edgeの場合
    } else if(userAgent.indexOf('edge') != -1) {
      $html.addClass('edge legacyBrowser');
    }
  }


  /**
   * MVのサイズ設定(SP)
   */
  MVSetting_SP();
  function MVSetting_SP() {
    var $mv =  $('.mv'),
        win_H = window.innerHeight;

    // ユーザーエージェントの判別
    // スマートフォンの場合はorientationchangeイベントを監視する
    // (スクロールするとresizeイベントが動作してがたついてしまうため)
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      window.addEventListener("load", resizeHandler);
      //window.addEventListener("resize", resizeHandler); //debug用
      window.addEventListener("orientationchange", resizeHandler);
    }
    function resizeHandler() {

      setTimeout(function(){
        win_H = window.innerHeight;
        $mv.css({
          'height' : win_H
        })
      },300);
    }
  }


  /**
   * IE、Edge用スライダー
   */
  legacySlider();
  function legacySlider() {
    $('#legacySlider').slick({
      infinite: true,
      arrows: false,
      dots: false,
      fade: true,
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 1500,
      pauseOnFocus: false,
      pauseOnHover: false,
      focusOnSelect: false,
    });
  }


  /**
   * Blog Slider
   */
  BlogSlider();
  function BlogSlider() {
    var mySwiper = new Swiper('.blogSlider', {
      loop: false,
      slidesPerView: 3.5, // スマホでは1枚を中央に、.5で見切れ具合を調整
      spaceBetween: 24,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1080: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 35,
        }
      },
    })
  }


  /**
   * ヘッダーを途中からFadeIn
   */
  HeaderFixed();
  function HeaderFixed() {
    var $target = $('.header');
    var controller = new ScrollMagic.Controller(); // 全体を制御する

    // ヘッダーを固定
    var scene = new ScrollMagic.Scene({
      triggerElement: ".news",
      triggerHook: 'onLeave'
    })
    // Sceneの指定
    .on("enter", function (event) {
      $target.fadeIn(500);
    })
    .on("leave", function (event) {
      $target.fadeOut(500);
    })
    .addTo(controller); // Controllerに追加
  }

})(jQuery);